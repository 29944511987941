@tailwind base;
@tailwind components;
@tailwind utilities;

.card.back {
  transform: rotateY(-180deg);
}
.react-date-picker__wrapper {
  border: none;
}

.y-divider {
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  border-right: none;
  opacity: 0.25;
  background-color: transparent;
  width: 0.0625rem;
  height: 100%;
  margin: 0px auto;
  background-image: linear-gradient(
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.4),
    rgba(52, 71, 103, 0)
  ) !important;
}

.x-divider {
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  opacity: 0.25;
  background-color: transparent;
  height: 0.0625rem;
  width: 100%;
  margin: 0px auto;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.4),
    rgba(52, 71, 103, 0)
  ) !important;
}
